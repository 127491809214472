.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    display: inline-block;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 60px;
    height: 30px;
    background: white;
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    position: relative;
    transition: background-color .2s;
    vertical-align: middle;
    /* top: -22px; */
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 1px 3px rgba(10, 10, 10, 0.29);

  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 0.5px);
    transform: translateX(-100%);
  }
  
  .react-switch-label:active .react-switch-button {
    width: 60px;
  }